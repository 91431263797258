import { Button } from "@material-ui/core";
import React from "react";
import Save from '@material-ui/icons/Save';
import { useNavigate } from "react-router-dom";


const SaveButton = ({onSave,...props}) => {
    const navigate = useNavigate();

    return (
        <Button variant="outlined" startIcon={<Save />} onClick={onSave} {...props}>
           SAVE
        </Button>
    );
}

export default SaveButton;