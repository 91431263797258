import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Input,
  SvgIcon,Grid
} from '@material-ui/core';
import { Search as SearchIcon,UserPlus,Map } from 'react-feather';
import { useForm } from "react-hook-form";
import FormInput from '../form-input';
import useSearchData from './useSearch';
import useSearchPaging  from 'src/hook/paging/search-paging';
import {NavButton} from '../buttons';

const SeachForm = (props) => {

  const serach = useSearchData();

  const { register, handleSubmit,control } = useForm();
  const onSubmit = (data) => {

    serach.onSearch(data)

    //alert(JSON.stringify(data));
    alert(JSON.stringify(serach.searchData));
  };


  return(
    <form onSubmit={handleSubmit(props.onSubmit)}>

  <Box {...props}>
  <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >

      {props.onAddNew &&
      <NavButton navUrl={'/app/user-manangement/addnew'} navTitle={'NEW USER'}/>}
      {/* <Button
               color="primary"
               variant="contained"
       >
         ADD NEW
       </Button>}
       <Button
              color="primary"
              variant="contained"
      sx={{ mx: 1 }}>
        Export
      </Button>
      <Button
        color="primary"
        variant="contained"
      >
        Add customer
      </Button> */}
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>

          <Grid container spacing={1} xs={12} sm={12}>

                {props.isEnableSearch &&
                <>
                <Grid item >
                  <FormInput control={control} name="searchText" />
                </Grid>

                <Grid item >
                  <Button variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>Search</Button>
                </Grid>
                </>}
                {props.onShowMap &&
                <Grid item >
                <Button variant="contained" color="primary" type="button" startIcon={<Map />}
                 onClick={()=>{props.onShowMap()}}
                >Map</Button>
              </Grid>
                }
          </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
  </form>
  )
};

export default SeachForm;
