import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PickupListResult from 'src/components/pickup/PickupListResult';
//import  from 'src/components/pickup/UserManagementToolbar';
import pickuplist from 'src/__mocks__/pickuplist';

const UserAuthorization = () => (
  <>
    <Helmet>
      <title>Pickup List | Pickup Tracking</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        {/* <CustomerListToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <PickupListResult pickuplist={pickuplist} />
        </Box>
      </Container>
    </Box>
  </>
);

export default UserAuthorization;
