import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container,TableCell } from '@material-ui/core';
import PickupTransactionListed from 'src/components/pickup/transaction-listed';
import {initialCriteria} from '../common/paging-criteria';
import SeachForm from '../components/serach-form';
import useSearchPaging from '../hook/paging/search-paging';
import { mapToRequest } from '../components/serach-form/transform';
import api from "../services/api";

const columns = [
  {
    Name:'Pickup No',
    Field:'pickUpNo',
    Link:'',
  },
  {
    Name:'Cust Code.',
    Field:'cfScusID',
  },
  {
    Name:'Cust Name.',
    Field:'customerNameMin',
  },
  {
    Name:'AR.',
    Field:'totalSumREQ',
  },
  {
    Name:'Payment',
    Field:'documentDate',
  },
  {
    Name:'Debit / Credit',
    Field:'totalSumCUT1',
  },
  {
    Name:'Balance',
    Field:'totalOS_Balance',
  }
];



const PickupList = () => {
  const paging = useSearchPaging('all-pickup-transaction', api.getPickupTransactions, initialCriteria, mapToRequest);

  useEffect(()=>{
    console.log(paging.data);
    console.log(paging.data?.data);
  },[paging.data])


  return(
    <>
      <Helmet>
        <title>Pickup List | Pickup Tracking</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {/* <CustomerListToolbar /> */}
          {/* <Box sx={{ pt: 3 }}>
            <PickupListResult pickuplist={pickuplist} />
          </Box> */}

        <SeachForm onSubmit={paging.onSearch} isEnableSearch={true}/>
        <Box sx={{ pt: 3 }}>
        {paging.data &&
          <PickupTransactionListed
          columns={columns}
          isFetching={paging.isFetching}
          datas={paging.data?.data}
          totalRecord={paging.data?.totalRecord}
          pageIndex={paging.pageIndex}
          pageSize={paging.pageSize}
          onChangePage={paging.onChangePage}
          onChangeRowsPerPage={paging.onChangeRowsPerPage}
          />}
        </Box>

        </Container>
      </Box>
    </>
  )
};

export default PickupList;
