import * as React from 'react';
import TextInput from '../inputs/text';
import InputError from '../input-error';
import { Input, Select, MenuItem } from "@material-ui/core";
import { Controller } from 'react-hook-form';

// type Props = {
//     as: any;
//     control: any;
//     error: any;
//     name: string;
//     label: string;
//     type?: string;
//     disabled?: boolean;
//     InputProps?: any;
//     onCheckboxChange?: (value: boolean) => void;
//     onSelect?: (value: any) => void;
// }

const FormInput = ({ as, control, error, ...props }) => {
    return (
        <>
            {/* <Controller as={as} control={control} error={!!error} {...props} InputLabelProps={{ shrink: true }}       /> */}
            <Controller
            render={({ field }) => (
              <TextInput {...field} {...props} />

            )}


            control={control}
            defaultValue={''}
            {...props}
          />

            {error && <InputError error={error} />}
        </>
    );
}

FormInput.defaultProps = {
    as: TextInput
}

export default FormInput;