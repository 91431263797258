import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import EntryCustomerPage from './pages/EntryCustomerPage';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import PickupList from 'src/pages/PickupList';
import PickupRoute from 'src/pages/PickupRoute';
import PickupRouteTransaction from './pages/PickupRouteTransaction';
import PickupLocation from 'src/pages/PickupLocation';
import UserManagement  from './pages/UserManagement';
import AddNewUser from './pages/AddNewUser';
import EntryUserPage from './pages/EntryUserPage';
import CustomerLocationPage from './pages/CustomerLocationPage';

import PickupNoLocationRouteMapPage from './pages/PickupNoLocationRouteMapPage'

import UserAuthorization from './pages/UserAuthorization';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'customers/:customerid', element: <EntryCustomerPage /> },
      { path: 'customers/maps', element: <CustomerLocationPage /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'pickup-list', element: <PickupList /> },
      { path: 'pickup-route', element: <PickupRoute /> },
      { path: 'pickup-route/:pickupno/:deviceid', element: <PickupRouteTransaction /> },  //PickupRouteTransaction  -- route transaction list
      { path: 'pickup-location/:deviceid/:pickupno', element: <PickupNoLocationRouteMapPage /> },  // PickupLocation  -- root location list
      { path: 'user-manangement', element: <UserManagement /> },
      { path: 'user-manangement/addnew', element: <AddNewUser /> }, // EntryUser
      { path: 'user-manangement/:usergid', element: <EntryUserPage /> }, // EntryUser
      { path: 'user-authorization', element: <UserAuthorization /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
