import { useMutation, useQuery } from "react-query";
import api from "../../../services/api";
import React from "react";




export function PickupRouteLocationHook(deviceid,pickupno,onFinish) {
    const [transactions, setTransactions] = React.useState([]);
    const [loding,setLoading] = React.useState(false);

    const { mutate: getData, isLoading, isError, error, status, isSuccess } = useMutation((request) => api.getPickupRouteLocation(deviceid,pickupno), {
        onMutate: ()=>{
           // console.log("isSuccess = ",isSuccess)
        },
        onSuccess: (response) => {
            // onSuccess();
            setTransactions(response.data)
            setLoading(true);
            //console.log("isSuccess = ",isSuccess)
            console.log("onSuccess pickupRouteLocationHook xxxx", response.data);
           // onFinish();
        },
        error: (response) => {
            console.log("error", response);
        }
    });


    const hndGetData = () => {
        getData();
    }


    return {
        hndGetData,
        isLoading: loding ,
        isError: isError ,
        error: error ,
        isSuccess: isSuccess ,
        data: transactions
    };



}