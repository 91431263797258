import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container,TableCell } from '@material-ui/core';
import UserListed from '../components/user/listed';

import {initialCriteria} from '../common/paging-criteria';

import useAPI  from 'src/hook/use-api/useAPI';
import { mapToRequest } from '../components/user/newuser/transform';
import api from "../services/api";
import { useNavigate,useParams } from "react-router-dom";
import { func } from 'prop-types';
import EntryUser from 'src/components/user/entry';




// {
//   "userName": "string",
//   "password": "string",
//   "confirmPassword": "string",
//   "email": "string",
//   "firstName": "string",
//   "lastName": "string"
// }


const initialData = {
    userName: '',
    password: '',
    confirmPassword: '',
    email: '',
    firstName: '',
    lastName: '',
}






const EntryUserPage = () => {
  const navigate = useNavigate();
  let { usergid } = useParams();

  function onSuccess(){
    //navigate("/app/user-manangement")
  }
  const useapi = useAPI('get-user-info', api.getUserInfo, initialData, mapToRequest,onSuccess,usergid);

  useEffect(()=>{
    console.log("-----");
    console.log(useapi.data);
    console.log(useapi.data?.data);

    console.log("gid");
    console.log(usergid);


  },[useapi.data])


  const onSubmit=()=>{

  };



  return(
    <>
      <Helmet>
        <title>User List | Pickup Tracking</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          {/* <CustomerListToolbar /> */}
          {/* <Box sx={{ pt: 3 }}>
            <PickupListResult pickuplist={pickuplist} />
          </Box> */}

        <EntryUser datas={useapi.data} onSubmit={useapi.onSubmit} onSuccess={onSuccess} usergid={usergid}/>
        {/* <Box sx={{ pt: 3 }}>
        {paging.data &&
          <UserListed
          columns={columns}
          isFetching={paging.isFetching}
          datas={paging.data?.data}
          totalRecord={paging.data?.totalRecord}
          pageIndex={paging.pageIndex}
          pageSize={paging.pageSize}
          onChangePage={paging.onChangePage}
          onChangeRowsPerPage={paging.onChangeRowsPerPage}
          />}
        </Box> */}

        </Container>
      </Box>
    </>
  )
};

export default EntryUserPage;

