import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {

    }
}));

// type Props = {
//     error: any;
// }

const InputError = (props) => {
    const style = useStyles();

    return (
        <Typography color="error" variant="body1" className={style.root}>{props.error?.message}</Typography>
    );
}

export default InputError;