import { useMutation, useQuery } from "react-query";
import api from "../services/api";
import { setUserInfo} from "../common/session-storeage";
import React from "react";


export function HookAuth(onSuccess) {
    const [authData, setAuthData] = React.useState([]);
    //const [loding,setLoading] = React.useState(false);


    //const {isLoading, isError, error, mutate} = useMutation(createPostArticle)
    const {isLoading, isError, error,isSuccess, mutate} = useMutation((request) => api.auth(request), {
   // const { mutate: getAuth, isLoading, isError, error, status, isSuccess } = useMutation((request) => api.auth(request), {
        onMutate: ()=>{
           console.log("isLoading = ",isLoading)
          // setLoading(true)
        },
        onSuccess: (response) => {
            // onSuccess();

            const userData = {
                userInfo: response.data.data.userInfo,
                token: response.data.data.accessToken,
               // refreshToken: response.data.data.refreshToken,
              };
            setUserInfo(userData);


            setAuthData(response.data)




            //console.log("isLoading = ",isLoading)
            //setLoading(false);
            //console.log("isSuccess = ",isSuccess)
            //console.log("onSuccess", response.data);
        },
        error: (response) => {
            //setLoading(false);
            console.log("error", response);
            //console.log("isLoading = ",isLoading)

        }
    });


    const hndGetAuth = (request) => {
        mutate(request);
    }


    return {
        hndGetAuth,
        isLoading: isLoading ,
        isError: isError ,
        error: error ,
        isSuccess: isSuccess ,
        data: authData
    };



}