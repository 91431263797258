import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';

import UserListed from '../components/user/listed';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {initialCriteria} from '../common/paging-criteria';
import SeachForm from '../components/serach-form';
import useSearchPaging from '../hook/paging/search-paging';
import { mapToRequest } from '../components/serach-form/transform';
import api from "../services/api";



import CustomToolbar  from 'src/components/toolbar';

const columns = [
  {
    Name:'Pickup No',
    Field:'pickUpNo',
    Link:'',
  },
  {
    Name:'Cust Code.',
    Field:'cfScusID',
  },
  {
    Name:'Cust Name.',
    Field:'customerNameMin',
  },
  {
    Name:'AR.',
    Field:'totalSumREQ',
  },
  {
    Name:'Payment',
    Field:'documentDate',
  },
  {
    Name:'Debit / Credit',
    Field:'totalSumCUT1',
  },
  {
    Name:'Balance',
    Field:'totalOS_Balance',
  }
];



const UserManagement = () => {
  const paging = useSearchPaging('all-user', api.getUserList, initialCriteria, mapToRequest);

  useEffect(()=>{
    console.log(paging.data);
    console.log(paging.data?.data);
  },[paging.data])


  return(
    <>
      <Helmet>
        <title>User List | Pickup Tracking</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          {/* <CustomerListToolbar /> */}
          {/* <Box sx={{ pt: 3 }}>
            <PickupListResult pickuplist={pickuplist} />
          </Box> */}

        <SeachForm onSubmit={paging.onSearch}
        isEnableSearch={true}
        onAddNew={()=>{console.log("add new")}}/>
        <Box sx={{ pt: 3 }}>
        {paging.data &&
          <UserListed
          columns={columns}
          isFetching={paging.isFetching}
          datas={paging.data?.data}
          totalRecord={paging.data?.totalRecord}
          pageIndex={paging.pageIndex}
          pageSize={paging.pageSize}
          onChangePage={paging.onChangePage}
          onChangeRowsPerPage={paging.onChangeRowsPerPage}
          />}
        </Box>

        </Container>
      </Box>
    </>
  )
};

export default UserManagement;

