import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PickupTransactionResult from 'src/components/pickup/PickupTransactionResult';
//import CustomerListToolbar from 'src/components/pickup/UserManagementToolbar';
import pickuplist from 'src/__mocks__/pickuplist';
import SeachForm from '../components/serach-form';
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom";




const PickupRouteTransaction = () =>{
    let { pickupno,deviceid } = useParams();
    const navigate = useNavigate();





    useEffect(()=>{
      //  console.log("pickup-no :::: ",pickupno);



  },[]);

    return(
  <>
    <Helmet>
      <title>Pickup Route | Pickup Route Transaction</title>
    </Helmet>

    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
      <SeachForm
          onShowMap={()=>{navigate(`/app/pickup-location/${deviceid}/${pickupno}`)}}
        />
        {/* <CustomerListToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <PickupTransactionResult pickupno={pickupno} />
        </Box>
      </Container>
    </Box>
  </>
)};

export default PickupRouteTransaction;
