/*global google*/
import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow
} from 'react-google-maps';

const containerStyle = {
  height: '98%',
  position: 'absolute',
  left: '0px',
  width: '100%',
  overflow: 'hidden'
};

class CustomerLocationMap extends Component {
  state = {
    directions: null,
    isOpen: true,
    showInfoIndex:null,
    origin: { lat: 13.979073116714108, lng: 100.31128534213052 },
    dest: { lat: 13.984377925726594, lng: 100.32233048262913 },
    // waypts: [
    //   {
    //     location: { lat: 13.984334276394067, lng: 100.32227789267937 }
    //   }
    // ]
    waypts:[]
  };

    handleToggleOpen = () => {
        this.setState({
            isOpen: true
        });
    }

    handleToggleClose = () => {
        this.setState({
            isOpen: false
        });
    }

    showInfo(a){
    this.setState({showInfoIndex: a ,isOpen:true })
    }

  componentDidMount() {

    console.log('xxx=>',this.props.datas);

    const directionsService = new google.maps.DirectionsService();

    const origin = { lat: 13.979073116714108, lng: 100.31128534213052};
    const destination = { lat: 13.984377925726594, lng: 100.32233048262913 };
    //const waypt = [{lat: 38.907192,lng: -77.036871}]

    // const waypts = [
    //   {
    //     location: { lat: 13.984334276394067, lng: 100.32227789267937 }
    //   }
    // ];

    let waypts0 = [];

    this.props.datas.map(itm=>{
      waypts0.push({
       // location: new google.maps.LatLng(itm.lat, itm.long),
        location: {lat:itm.lat, lng:itm.long},
        stopover: true,
        custCode:itm.custCode,
        custName:itm.custName
      });



    });

    this.setState({waypts:waypts0})


      console.log("xxxxxx waypts0");
      console.log(waypts0);


    directionsService.route(
      {
        origin: this.state.origin,
        destination: this.state.dest,
        waypoints: this.state.waypts,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: 13.979073116714108, lng: 100.31128534213052}}
        defaultZoom={13}
      >
        {/* {this.state.directions != null && (
          <DirectionsRenderer
            directions={this.state.directions}
            options={{ suppressMarkers: true }}
          />
        )} */}
        {/* <Marker position={this.state.origin} />
        <Marker position={this.state.dest} /> */}
        {this.state.waypts.map((waypt,idx) => (
          // <Marker
          //   position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
          //   icon={'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'}
          //   label={'Twenty Soft Co.,Ltd.'}
          // />

          <Marker
          key={idx}
          options={{icon: 'https://i.imgur.com/9G5JOp8.png'}}
          position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
          onClick={()=>{ this.showInfo(idx)} }
        >


           <InfoWindow  onCloseClick={this.handleToggleClose}>
            <div>
              <div>{waypt.custCode}</div>
              <div >{waypt.custName}</div>
            </div>
          </InfoWindow>

          {/* { (this.state.isOpen && (this.state.showInfoIndex == idx )) &&
          <InfoWindow  onCloseClick={this.handleToggleClose}>
            <div>
              <div>{waypt.custCode}</div>
              <div >{waypt.custName}</div>
            </div>
          </InfoWindow>}*/}
        </Marker>


        ))}
      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={containerStyle} />}
          mapElement={<div style={containerStyle} />}
          containerElement={<div style={containerStyle} />}
        />
      </div>
    );
  }
}

export default CustomerLocationMap;
