/* eslint-disable */
import { useState,useEffect } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loading from '../loading';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import { HookAPickupTransaction } from '../../hook/geta-transaction';


const PickupTransactionResult = ({ pickupno, ...rest }) => {

  const hookTransaction = HookAPickupTransaction(() => { });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  //const hookPickupTransaction = HookPickupTransaction(() => { });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const hndSample = () => {

  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(()=>{
    hookTransaction.hndGetATransctions(pickupno);
  },[]);



  return (
    <Card {...rest}>
      {!hookTransaction.data.data &&
      <Loading/>}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Pickup No
                </TableCell>
                <TableCell>
                  Cust Code.
                </TableCell>
                <TableCell>
                  Cust Name
                </TableCell>
                <TableCell>
                  AR
                </TableCell>
                <TableCell>
                  Payment
                </TableCell>
                <TableCell>
                  Debit / Credit
                </TableCell>
                <TableCell>
                  Balance
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hookTransaction.data.data && hookTransaction.data.data.slice(page*limit,(page+1)*limit).map((data) => (
                <TableRow
                  hover
                  key={data.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                        {data.pickUpNo}

                    </Box>
                  </TableCell>
                  <TableCell>
                  {data.cfScusID}
                  </TableCell>
                  <TableCell>
                    {`${data.customerNameMin}`}
                  </TableCell>
                  <TableCell>
                    {data.totalSumREQ}
                  </TableCell>
                  <TableCell>
                    {moment(data.documentDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {data.totalSumCUT1} {data.totalSumCUT2}
                  </TableCell>
                  <TableCell>
                    {data.totalOS_Balance}
                  </TableCell>
                  <TableCell>
                    {data.statusFlag}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={hookTransaction.data.data && hookTransaction.data.data.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

// PickupTransactionResult.propTypes = {
//   pickupno: PropTypes.integer.isRequired
// };

export default PickupTransactionResult;
