
export function mapToRequest(search, pageIndex, pageSize) {
    return {
        criteria: {
            docNo: '',
            searchText: search.searchText,
            docKey: 0,
            refDocNo: '',
            refDocKey: 0,
            refDocKey1: 0,
            refDocKey2: 0,
            refDocKey3: 0,
            refDocKey4: 0,
            refDocKey5: 0,
            id: 0,
            refDouble1: 0,
            refDouble2: 0
        },
        pageIndex,
        pageSize
    };
}
