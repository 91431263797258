import React from 'react';
import { makeStyles, TablePagination } from '@material-ui/core';
import TablePaginationActions from './action';
import PaginationControlled from './PaginationControlled';

//import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from "@material-ui/lab/PaginationItem";
import { Link, useParams } from "react-router-dom";



// const useStyle = makeStyles((theme) => ({
//     root: {
//         '& .MuiTablePagination-spacer': {
//             flex: '1 1'
//         }
//     }
// }))


const useStyle = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));


// type Props = {
//     total: number;
//     pageSize: number;
//     pageIndex: number;
//     onChangePage: (e: any, newPage: number) => void;
//     onChangeRowsPerPage: (e: any) => void;
// }



const APagination = ({ total, pageSize, pageIndex, onChangePage, onChangeRowsPerPage, ...props }) => {
    const style = useStyle();

    const handleChange = (event, value) => {
        //setPage(value);
        console.log(value);
        onChangePage(event, value);
      };


    return (
        <>
        {/* <div className={style.root}>
            <Pagination count={10} shape="rounded" />
            <Pagination count={10} variant="outlined" shape="rounded" />
        </div> */}




        {/* <Pagination count={Math.max(0, Math.ceil(total / pageSize))} variant="outlined" shape="rounded"
        page={pageIndex+1} onChange={handleChange}
        showFirstButton showLastButton
        component="div"
        {...props}
        /> */}

            {/*
            <Pagination
                page={Number(1)}
                count={5}
                shape="rounded"
                color="primary"
                boundaryCount={2}
                showFirstButton
                showLastButton
                renderItem={(item) => (
                <PaginationItem
                    type={"start-ellipsis"}
                    component={Link}
                    selected
                    to={``}
                    {...item}
                />
                )}
            /> */}


          <TablePagination
            component="div"
            variant="outlined" shape="rounded"
             className={style.root}
             rowsPerPageOptions={[10,20, 40, 60, 100]}
             count={total}
             rowsPerPage={pageSize}
             page={pageIndex}
             // SelectProps={{
             //     inputProps: { 'aria-label': 'rows per page' },
             //     native: true,
             // }}
             onChangePage={onChangePage}
             onChangeRowsPerPage={onChangeRowsPerPage}
             //ActionsComponent={TablePaginationActions}
             //ActionsComponent={TablePaginationActions}

             {...props} />

             {/* <PaginationControlled/> */}

       {/* <TablePagination
        component="div"

        rowsPerPageOptions={[5, 10, 25]}
      /> */}

        </>

        // <TablePagination
        //    variant="outlined" shape="rounded"
        //     className={style.root}
        //     rowsPerPageOptions={[10,20, 40, 60, 100]}
        //     count={total}
        //     rowsPerPage={pageSize}
        //     page={pageIndex}
        //     // SelectProps={{
        //     //     inputProps: { 'aria-label': 'rows per page' },
        //     //     native: true,
        //     // }}
        //     onChangePage={onChangePage}
        //     onChangeRowsPerPage={onChangeRowsPerPage}

        //     {...props} />
    );
}

export default APagination;