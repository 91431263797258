/* eslint-disable */
import { useState,useEffect } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loading from '../loading';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import { HookPickupTransaction } from '../../hook/getall-transaction';


const PickupListResult = ({ pickuplist, ...rest }) => {

  const hookTransaction = HookPickupTransaction(() => { });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  //const hookPickupTransaction = HookPickupTransaction(() => { });

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const hndSample = () => {

  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(()=>{
    hookTransaction.hndGetAllTransctions();
  },[]);



  return (
    <Card {...rest}>
      {!hookTransaction.data &&
      <Loading/>}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Pickup No
                </TableCell>
                <TableCell>
                  Cust Code.
                </TableCell>
                <TableCell>
                  Cust Name
                </TableCell>
                <TableCell>
                  AR
                </TableCell>
                <TableCell>
                  Payment
                </TableCell>
                <TableCell>
                  Debit / Credit
                </TableCell>
                <TableCell>
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hookTransaction.data && hookTransaction.data.slice(page*limit,(page+1)*limit).map((data) => (
                <TableRow
                  hover
                  key={data.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    ><RouterLink to={`/app/pickup-location/${data.imeI_No}/${data.pickupNo}`}>
                      <Typography
                        color="primary"
                        variant="body1"
                      >
                        {data.pickUpNo}
                      </Typography>
                      </RouterLink>
                    </Box>
                  </TableCell>
                  <TableCell>
                  {data.cfScusID}
                  </TableCell>
                  <TableCell>
                    {`${data.customerNameMin}`}
                  </TableCell>
                  <TableCell>
                    {data.totalSumREQ}
                  </TableCell>
                  <TableCell>
                    {moment(data.documentDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {data.totalSumCUT1} {data.totalSumCUT2}
                  </TableCell>
                  <TableCell>
                    {data.totalOS_Balance}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={hookTransaction.data && hookTransaction.data.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

PickupListResult.propTypes = {
  pickuplist: PropTypes.array.isRequired
};

export default PickupListResult;
