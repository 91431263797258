import { useState,useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';

import { HookAuth } from '../hook/auth';

const Login = () => {
  const navigate = useNavigate();
  const hookAuth = HookAuth(() => { });



  const handleLogin=(values)=>{


      console.log(values);

      let data = {
        username:values.email,
        password:values.password
      }

      // //console.log(hookAuth.isLoading);
      hookAuth.hndGetAuth(data);

      // if (hookAuth.isSuccess){
      //   console.log("login success");
      // }else{
      //   console.log("login fail");
      // }
      // //navigate('/app/dashboard111', { replace: true });


  }



  useEffect(()=>{
    //console.log(hookAuth.data);
   // console.log("hook data ",hookAuth.isLoading);

    if (!hookAuth.isError){
      if (hookAuth.isSuccess){
         // console.log(hookAuth.data.data.token);
         navigate('/app/dashboard', { replace: true });
      }

    }

    //if (hookAuth.data.is)

   // console.log(hookAuth.data.data.token)
   // console.log(hookAuth.data.isSuccess)

  },[hookAuth.isLoading]);

  return (
    <>
      <Helmet>
        <title>Login | Pickup Tracking</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">

          <Formik
            initialValues={{
              email: 'demo@twenty-soft.com',
              password: '123456'
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().max(255).required('Usernae / Email is required'),  // .email('Must be a valid email')
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              //navigate('/app/dashboard', { replace: true });
              handleLogin(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform


                  </Typography>

                  {hookAuth.isLoading &&
                    <CircularProgress />
                  }





{/*
                  {hookAuth.data.data &&
                    <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {hookAuth.data.data.token}

                  </Typography>
                  } */}

                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  {/* <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      startIcon={<FacebookIcon />}
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      Login with Facebook
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      Login with Google
                    </Button>
                  </Grid> */}
                </Grid>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  {/* <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    or login with email address
                  </Typography> */}

                  { hookAuth.isError &&
                    <Alert severity="error">Invalid Username or Password !!!</Alert>
                  }

                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Username / Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={hookAuth.isLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
