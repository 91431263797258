import { useMutation, useQuery } from "react-query";
import api from "../../../services/api";
import React from "react";




export function UserHook(usergid,onFinish) {
    const [transactions, setTransactions] = React.useState([]);
    const [loding,setLoading] = React.useState(false);

    const { mutate: update, isLoading, isError, error, status, isSuccess } = useMutation((request) => api.updateUserInfo(usergid,request), {
        onMutate: ()=>{
           // console.log("isSuccess = ",isSuccess)
        },
        onSuccess: (response) => {
            // onSuccess();
            setTransactions(response.data)
            setLoading(true);
            //console.log("isSuccess = ",isSuccess)
            //console.log("onSuccess", response.data);
            onFinish();
        },
        error: (response) => {
            console.log("error", response);
        }
    });


    const hndUpdate = (data) => {
        update(data);
    }


    return {
        hndUpdate,
        isLoading: loding ,
        isError: isError ,
        error: error ,
        isSuccess: isSuccess ,
        data: transactions
    };



}