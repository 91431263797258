/* eslint-disable */
import { useState,useEffect } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import APagination from '../../pagination';
import Loading from '../../loading';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const formatDate=(dt)=>{

  if (moment(dt).isValid()){
    return moment(dt).format('DD/MM/YYYY HH:mm')
  }else {
    return '';
  }
}

const PickupListed = ({ datas,totalRecord,onChangePage,onChangeRowsPerPage,pageIndex,pageSize,isFetching,DataTable,columns,
  ...rest }) => {



  return (
    <Card {...rest}>
      {isFetching && <Loading/>}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Regis No
                </TableCell>
                <TableCell>
                  Trans Date
                </TableCell>
                <TableCell>
                  Pickup No
                </TableCell>
                <TableCell>
                  Count Cust
                </TableCell>
                <TableCell>
                  Start Time
                </TableCell>
                <TableCell>
                  End Time
                </TableCell>
                <TableCell>
                  Used Time
                </TableCell>
                <TableCell>
                  Complete Status
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {datas && datas.map((data) => (
                  <TableRow
                  hover
                  key={data.id}
                >
                  <TableCell>
                    <Box
                    >
                        {data.regisNo}

                    </Box>
                  </TableCell>
                  <TableCell>
                    {moment(data.transDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>

                  {data.pickUpNo}

                  </TableCell>
                  <TableCell>
                  {data.itemCount}
                  </TableCell>
                  <TableCell>
                   {/* {moment(data.startAt).format('DD/MM/YYYY HH:MM')} */}
                   {formatDate(data.startAt)}
                  </TableCell>

                  <TableCell>
                    {/* {moment(data.finishedAt).format('DD/MM/YYYY HH:MM')} */}
                    {formatDate(data.finishedAt)}
                  </TableCell>
                  <TableCell>
                    {data.useTimeDesc}
                  </TableCell>
                  <TableCell>
                    {data.documentCompletedFlag}
                  </TableCell>
                  <TableCell>
                    <Button
                    href={`/app/pickup-route/${data.pickUpNo}/${data.deviceId}`}
                    color="primary" startIcon={<ArrowForwardIosIcon />} >Detail</Button>
                    {/* <Button color="primary" startIcon={<DeleteIcon />} >DELETE</Button> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <APagination total={totalRecord}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage} />
    </Card>
  );
};

export default PickupListed;
