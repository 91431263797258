/* eslint-disable */
import { useState,useEffect } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import APagination from '../../pagination';
import Loading from '../../loading';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';


const PickupTransactionListed = ({ datas,totalRecord,onChangePage,onChangeRowsPerPage,pageIndex,pageSize,isFetching,columns,
  ...rest }) => {

  return (
    <Card {...rest}>
      {isFetching && <Loading/>}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>
                  Pickup No
                </TableCell>
                <TableCell>
                  Cust Code.
                </TableCell>
                <TableCell>
                  Cust Name
                </TableCell>
                <TableCell>
                  AR
                </TableCell>
                <TableCell>
                  Payment
                </TableCell>
                <TableCell>
                  Debit / Credit
                </TableCell>
                <TableCell>
                  Balance
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {datas && datas.map((data) => (
                <TableRow
                  hover
                  key={data.id}
                >

                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    ><RouterLink to={`/app/pickup-location/${data.imeI_No}/${data.pickUpNo}`}>

                        {data.pickUpNo}

                      </RouterLink>
                    </Box>
                  </TableCell>
                  <TableCell>
                  {data.cfScusID}
                  </TableCell>
                  <TableCell>
                    {`${data.customerNameMin}`}
                  </TableCell>
                  <TableCell>
                    {data.totalSumREQ}
                  </TableCell>
                  <TableCell>
                    {moment(data.documentDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {data.totalSumCUT1} {data.totalSumCUT2}
                  </TableCell>
                  <TableCell>
                    {data.totalOS_Balance}
                  </TableCell>
                  <TableCell>
                    {data.statusFlag}
                  </TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <APagination total={totalRecord}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage} />
    </Card>
  );
};

export default PickupTransactionListed;
