import create from 'zustand';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { AxiosResponse } from 'axios';

const usePageStore = create(set => ({
    pages: {},
    setPageState: (pageName, value) => set((state) => ({
        pages: {
            ...state.pages,
            [pageName]: value
        }
    })),
}))

const defaultPageIndex = 0;
const defaultPageSize = 10;
const defaultPage = {
    pageIndex: defaultPageIndex,
    pageSize: defaultPageSize
}

function useSearchPaging(pageName, api, initialSearch, mapper) {
    const [page, setPageState] = usePageStore((state) => [state.pages[pageName] || defaultPage, state.setPageState]);
    const request = mapper(page?.criteria || initialSearch, page?.pageIndex || defaultPageIndex, page?.pageSize || defaultPageSize);
    const { data, isFetching, error, refetch } =
            useQuery([pageName, request], () => api(request),
            {
                initialData: { ...{} , data: { data: [], totalRecord: 0 } },
            });

    // https://react-query.tanstack.com/guides/query-functions

    useEffect(() => {
       search(page?.criteria || initialSearch, page?.pageIndex || defaultPageIndex, page?.pageSize || defaultPageSize);
    }, []);

    function search(criteria, pageIndex, pageSize) {
        setPageState(pageName, { criteria, pageIndex, pageSize });
    }

    function onSearch(value) {
        search(value, 0, page.pageSize);
    }

    function onChangePage(e, newPage) {
        search(page.criteria, newPage, page.pageSize);
    }

    function onChangeRowsPerPage(e) {
        const rowPerPage = parseInt(e.target.value, 10);
        search(page.criteria, 0, rowPerPage);
    }

    return {
        ...page,
        onSearch,
        data:data?.data,
        isFetching,
        error,
        onChangePage,
        onChangeRowsPerPage,
        refetch
    };
}

export default useSearchPaging;