/*global google*/
import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow
} from 'react-google-maps';

const containerStyle = {
  height: '98%',
  position: 'absolute',
  left: '0px',
  width: '100%',
  overflow: 'hidden'
};

class PickupNoLocationRouteMap extends Component {
  state = {
    directions: null,
    isOpen: false,
    showInfoIndex:null,
    // origin: { lat: 13.975073116714108, lng: 100.31528534213052},
    // dest: { lat: 13.975073116714108, lng: 100.31628534213052 },
    origin: { lat: 0, lng: 0},
    dest: { lat: 0, lng: 0 },
    isLoading:true,

    waypts:[]
    // waypts: [
    //   {
    //     location: { lat: 13.975073116714108, lng: 100.31528534213052 }
    //   }
    // ]
  };

  handleToggleOpen = () => {

    this.setState({
        isOpen: true
    });
}

handleToggleClose = () => {
    this.setState({
        isOpen: false
    });
}

showInfo(a){
  this.setState({showInfoIndex: a ,isOpen:true })
 }

  componentWillUnmount(){

  }

  async componentDidMount() {
    const directionsService = new google.maps.DirectionsService();

    // const origin = { lat: 13.979073116714108, lng: 100.31128534213052 };
    // const destination = { lat: 13.984377925726594, lng: 100.32233048262913 };
    // //const waypt = [{lat: 38.907192,lng: -77.036871}]
    // const waypts = [
    //   {
    //     location: { lat: 38.907192, lng: -77.036871 }
    //   }
    // ];

    if (this.props.datas){

      console.log("datas => ",this.props.datas);

      if (this.props.datas.length>0){
        this.setState({origin:{lat:this.props.datas[0].lat,lng:this.props.datas[0].lng}})
        this.setState({dest:{lat:this.props.datas[this.props.datas.length-1].lat,lng:this.props.datas[this.props.datas.length-1].lng}})
      }

      await this.props.datas.map(loc=>{
        this.state.waypts.push(
          {
            location: { lat: loc.lat, lng: loc.lng }
          }
        );
      })

      this.setState({isLoading:false});
    }



    //origin: {  lat: 13.979073116714108, lng: 100.31128534213052 },


    directionsService.route(
      {
        origin: this.state.origin,
        destination: this.state.dest,
        waypoints: this.state.waypts,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: this.state.origin.lat, lng:this.state.origin.lng}}
        defaultZoom={13}
      >
        {this.state.directions != null && (
          <DirectionsRenderer
            directions={this.state.directions}
            options={{ suppressMarkers: true }}
          />
        )}
        <Marker position={this.state.origin} />
        <Marker position={this.state.dest} />
        {/* {this.state.waypts.map((waypt,idx) => (
          // <Marker
          //   position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
          //   icon={'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'}
          //   label={'Twenty Soft Co.,Ltd.'}
          // />

          <Marker
          key={idx}
          options={{icon: 'https://i.imgur.com/9G5JOp8.png'}}
          position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
          onClick={()=>{ this.showInfo(idx)} }
        >
          { (this.state.isOpen && (this.state.showInfoIndex == idx )) &&
          <InfoWindow  onCloseClick={this.handleToggleClose}>
            <div>
              <div>Twenty Soft</div>
              <div >XXXXXXXX</div>
            </div>
          </InfoWindow>}
        </Marker>


        ))} */}
      </GoogleMap>
    ));

    return (
      <div>
        {!this.state.isLoading &&
        <GoogleMapExample
          containerElement={<div style={containerStyle} />}
          mapElement={<div style={containerStyle} />}
          containerElement={<div style={containerStyle} />}
        />}
      </div>
    );
  }
}

export default PickupNoLocationRouteMap;
