import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container,TableCell } from '@material-ui/core';
//import PickupResult from 'src/components/pickup/PickupResult';

import DataTable from 'src/components/datatable';
import PickupListed from 'src/components/pickup/listed';
import {initialCriteria} from '../common/paging-criteria';
import SeachForm from '../components/serach-form';
import useSearchPaging from '../hook/paging/search-paging';
import { mapToRequest } from '../components/serach-form/transform';
import api from "../services/api";

const columns = [
  {
    Name:'Regis No',
    Field:'regisNo',
    Link:'',
  },
  {
    Name:'Trans Date',
    Field:'transDate',
  },
  {
    Name:'Pickup No',
    Field:'pickUpNo',
  },
  {
    Name:'Count Cust',
    Field:'itemCount',
  },
  {
    Name:'Start Time',
    Field:'startAt',
  },
  {
    Name:'End Time',
    Field:'finishedAt',
  },
  {
    Name:'Used Time',
    Field:'usedTime',
  },
  {
    Name:'Complete Status',
    Field:'documentCompletedFlag',
  }
];

const makeTableHeader=()=>{
  return(
    <>

        {columns && columns.map((col)=>{
                  return(
                    <TableCell>
                      {col.Name}
                    </TableCell>)
                })}

    </>

  )
};





const PickupRoute = () => {
  const paging = useSearchPaging('pickup-result', api.getPickup, initialCriteria, mapToRequest);
  return(
  <>
    <Helmet>
      <title>Pickup List | Pickup Tracking</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <SeachForm onSubmit={paging.onSearch} isEnableSearch={true}/>
        <Box sx={{ pt: 3 }}>
          {paging.data &&
          <PickupListed
          tableHeader={makeTableHeader()}
          columns={columns}
          datas={paging.data?.data}
          totalRecord={paging.data?.totalRecord}
          pageIndex={paging.pageIndex}
          pageSize={paging.pageSize}
          onChangePage={paging.onChangePage}
          onChangeRowsPerPage={paging.onChangeRowsPerPage}
          />}
        </Box>
      </Container>
    </Box>
  </>
)};

export default PickupRoute;
