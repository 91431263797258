
import { useState } from 'react';

function useSearch() {
    const [searchData, setSearchData] = useState([]);

    function onSearch(newSearchData) {
        setSearchData(newSearchData);
    }

    function resetData() {
        setSearchData([]);
    }

    return {
        searchData,
        onSearch,
        resetData
    }
}

export default useSearch;