import { useEffect  } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    Input,
    SvgIcon,Grid
  } from '@material-ui/core';
  import { Search as SearchIcon,UserPlus } from 'react-feather';
  import { useForm } from "react-hook-form";
  import FormInput from '../../form-input';
  import {BackButton,SaveButton} from '../../buttons';
  import { useNavigate } from "react-router-dom";
  import {UserHook} from '../hook/userHook';




  const EntryUser = (props) => {
    const { register, setValue,handleSubmit,control } = useForm();
    const navigate = useNavigate();
    const userHook = UserHook(props.usergid,()=>{
      navigate("/app/user-manangement")
    });


    const onSubmit = (data) => {
        userHook.hndUpdate(data);
        //alert(JSON.stringify(data));
        //navigate("/app/user-manangement")
        //props.onSubmit
      };

      useEffect(()=>{
       // props.onSubmit();
      },[]);

      useEffect(()=>{
        if (props.datas){
        }

        setValue('firstName',props.datas.firstName);
        setValue('lastName',props.datas.lastName);
        setValue('email',props.datas.email);
        setValue('username',props.datas.userName);
        setValue('password',props.datas.password);
        setValue('confirmPassword',props.datas.password);
        console.log("datas");
        console.log(props.datas);

      },[props.datas])


    return(
      <form onSubmit={handleSubmit(onSubmit)}>

    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>

            <Grid container spacing={1} xs={12} sm={12}>
                  <Grid item xs={12} sm={12} >
                    <FormInput control={control} name="firstName" label="FirstName" />
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <FormInput control={control} name="lastName" label="LastName"/>
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <FormInput control={control} name="email" label="Email"/>
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <FormInput control={control} name="username" label="UserName" disabled/>
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <FormInput control={control} name="password" label="Password" type="password"/>
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <FormInput control={control} name="confirmPassword" label="Confirm Password" type="password"/>
                  </Grid>

            </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt:2
      }}
    >
      {/* <Button
        color="primary"
        variant="contained"
      >
        OK
      </Button> */}
      <Grid container spacing={1} xs={12} sm={12}

      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      >
      <Grid item  >
          <SaveButton type="submit"/>
      </Grid>
      <Grid item  ><BackButton   /></Grid>
      </Grid>
      {/* <Button sx={{ mx: 1 }}
        color="primary"
        variant="contained"
        onClick={() => navigate(-1)}
      >
        CANCEL
      </Button> */}
    </Box>
    </Box>
    </form>
    )
  };

  export default EntryUser;
