import React from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import fetch from 'isomorphic-fetch';
import config from '../../services/api-config';
const containerStyle = {
    height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'hidden'
 };
const MapWithAMarkers = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDbTO1Zu-zBBmX9hgk-uUpZz001i-V3Ffo",
        loadingElement: <div style={containerStyle} />,
        //containerElement: <div style={{ height: `600px` }} />,
        containerElement: <div style={containerStyle} />,

        //height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; background-color: rgb(229, 227, 223);
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentDidMount() {

            this.setState({

                zoomToMarkers: map => {
                    console.log("Zoom to markers");
                    const bounds = new window.google.maps.LatLngBounds();
                    map?.props.children.forEach((child) => {
                        if (child.type === Marker) {
                            bounds.extend(new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng));
                        }
                    })
                    map?.fitBounds(bounds);
                }
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap ref={props.zoomToMarkers} defaultZoom={5} defaultCenter={{ lat: 25.0391667, lng: 121.525 }}>
        {props.markers.map(marker => (
            <Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
            />
        ))}
    </GoogleMap>
    );

class PickupLocations extends React.PureComponent {
    componentWillMount() {
        this.setState({ markers: [] })
    }

    componentDidMount() {

        const {deviceid,pickupno} = this.props;
        console.log("device id === "+deviceid);

        // if (deviceid==null){
        //     deviceid="E3C3C8D3-612D-4AFD-B290-6ACEE4019253";
        // }

        // const url = [
        //     // Length issue
        //     `https://gist.githubusercontent.com`,
        //     `/vgrem/e2e488fbe70372854174aba38cd3d820`,
        //     `/raw/71a5a528bb229c2bf9dd49c4c2d6d8d080245d33/data.json`
        // ].join("")

        //E3C3C8D3-612D-4AFD-B290-6ACEE4019253


        //const url =config.apiEndpoint+"api/pickup-tracking/locations/"+deviceid;
        const url =`${config.apiEndpoint}api/pickup-tracking/locations/${deviceid}/${pickupno}`;


        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.setState({ markers: data });
            });
    }

    render() {
        return (
            this.state.markers.length > 0 && <MapWithAMarkers markers={this.state.markers} />
        )
    }
}

export default PickupLocations;