import { useMutation, useQuery } from "react-query";
import api from "../services/api";
import React from "react";


export function HookAPickupTransaction(onSuccess) {
    const [transactions, setTransactions] = React.useState([]);
    const [loding,setLoading] = React.useState(false);

    const { mutate: getAllTransctions, isLoading, isError, error, status, isSuccess } = useMutation((pickupNo) => api.getAPickupTransactions(pickupNo), {
        onMutate: ()=>{
           // console.log("isSuccess = ",isSuccess)
        },
        onSuccess: (response) => {
            // onSuccess();
            setTransactions(response.data)
            setLoading(true);
            //console.log("isSuccess = ",isSuccess)
            //console.log("onSuccess", response.data);
        },
        error: (response) => {
            console.log("error", response);
        }
    });


    const hndGetATransctions = (pickupNo) => {
        getAllTransctions(pickupNo);
    }


    return {
        hndGetATransctions,
        isLoading: loding ,
        isError: isError ,
        error: error ,
        isSuccess: isSuccess ,
        data: transactions
    };



}