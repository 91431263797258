import create from 'zustand';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import {defaultPageIndex,defaultPageSize,defaultPage}  from '../../common/constant';

const usePageStore = create(set => ({
    pages: {},
    setPageState: (pageName, value) => set((state) => ({
        pages: {
            ...state.pages,
            [pageName]: value
        }
    })),
}))


function useAPI(pageName, api, initialCriteria, mapper,onSuccess,param1) {
    const [page, setPageState] = usePageStore((state) => [state.pages[pageName] || defaultPage, state.setPageState]);
    //const request = mapper(page?.criteria || initialCriteria, page?.pageIndex || defaultPageIndex, page?.pageSize || defaultPageSize);
    const request = param1==""?page?.criteria:param1;//mapper(page?.criteria || initialCriteria, page?.pageIndex || defaultPageIndex, page?.pageSize || defaultPageSize);
    const { data, isFetching, error, refetch } =
            useQuery([pageName, request], () => api(request),
            {
                initialData: { ...{} , data: { data: [], totalRecord: 0 } },
            });
    // https://react-query.tanstack.com/guides/query-functions

    useEffect(() => {
        //getdata(page?.criteria || initialCriteria, page?.pageIndex || defaultPageIndex, page?.pageSize || defaultPageSize);
    }, []);

    async function getdata(criteria, pageIndex, pageSize) {
        setPageState(pageName, { criteria, pageIndex, pageSize });
    }

    async function onSubmit(request) {
        // console.log(" form data =");
        // console.log(request);
        await getdata(request, 0, page.pageSize);
        onSuccess();
    }

    function onChangePage(e, newPage) {
        getdata(page.criteria, newPage, page.pageSize);
    }

    function onChangeRowsPerPage(e) {
        const rowPerPage = parseInt(e.target.value, 10);
        getdata(page.criteria, 0, rowPerPage);
    }

    return {
        ...page,
        onSubmit,
        data:param1==""?data?.data?.data:data.data,
        isFetching,
        error,
        onChangePage,
        onChangeRowsPerPage,
        refetch
    };
}

export default useAPI;