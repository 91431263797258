import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PickupListResult from 'src/components/pickup/PickupListResult';
//import CustomerListToolbar from 'src/components/pickup/UserManagementToolbar';
import pickuplist from 'src/__mocks__/pickuplist';

import CustomerLocationMap  from 'src/components/pickup/CustomerLocationMap';
import { withScriptjs } from 'react-google-maps';

import {initialCriteria} from '../common/paging-criteria';
import SeachForm from '../components/serach-form';
import useSearchPaging from '../hook/paging/search-paging';
import { mapToRequest } from '../components/serach-form/transform';
import api from "../services/api";

import {useParams} from "react-router-dom";
import { useEffect } from 'react';

const CustomerLocationPage = () => {
  let { deviceid } = useParams();
  const paging = useSearchPaging('get-all-customer', api.getAllCustomer, initialCriteria, mapToRequest);

  const MapLoader = withScriptjs(CustomerLocationMap);

  const containerStyle = {
    height: '100%',
    position: 'absolute',
    left: '0px',
    width: '100%',
    overflow: 'hidden'
  };


  useEffect(()=>{
    paging.onSearch();
  },[]);

  return(
  <>
    <Helmet>
      <title>Customer Location | Pickup Tracking</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box sx={{ pt: 0 }}>

        <MapLoader
        datas={paging.data?.data}
        gid={'999999'}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbTO1Zu-zBBmX9hgk-uUpZz001i-V3Ffo"
        loadingElement={<div style={containerStyle} />}

        />


        </Box>
      </Container>
    </Box>
  </>
)};

export default CustomerLocationPage;
