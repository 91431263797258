import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PickupListResult from 'src/components/pickup/PickupListResult';
//import CustomerListToolbar from 'src/components/pickup/UserManagementToolbar';
import pickuplist from 'src/__mocks__/pickuplist';

import PickupNoLocationRouteMap  from 'src/components/pickup/PickupNoLocationRouteMap';
import { withScriptjs } from 'react-google-maps';

import {initialCriteria} from '../common/paging-criteria';

import useSearchPaging from '../hook/paging/search-paging';
import { mapToRequest } from '../components/serach-form/transform';
import api from "../services/api";
import {PickupRouteLocationHook} from '../components/pickup/hook/pickupRouteLocationHook';

import {useParams} from "react-router-dom";

const PickupNoLocationRouteMapPage = () => {
  let { deviceid,pickupno } = useParams();

  //const paging = useSearchPaging('get-pickup-route-location', api.getPickupRouteLocation, initialCriteria, mapToRequest);
 // const pickupRouteLocationHook = PickupRouteLocationHook('FBF8CDA0-F563-448B-9D50-3728C0F0A93E','64000012',()=>{
  const pickupRouteLocationHook = PickupRouteLocationHook(deviceid,pickupno,()=>{
    // navigate("/app/user-manangement")
  });

  const MapLoader = withScriptjs(PickupNoLocationRouteMap);

  const containerStyle = {
    height: '100%',
    position: 'absolute',
    left: '0px',
    width: '100%',
    overflow: 'hidden'
  };


  useEffect(()=>{
    pickupRouteLocationHook.hndGetData();
    console.log("pickup-no :::: >>> ",pickupno);

  },[]);


  useEffect(()=>{
    console.log("way point datas.=>")
    console.log(pickupRouteLocationHook.data);
  },[pickupRouteLocationHook.data]);


  return(
  <>
    <Helmet>
      <title>Pickup Location | Pickup Tracking</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box sx={{ pt: 0 }}>

        {pickupRouteLocationHook.data &&
        <MapLoader
        datas={pickupRouteLocationHook.data}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbTO1Zu-zBBmX9hgk-uUpZz001i-V3Ffo"
        loadingElement={<div style={containerStyle} />}
        />}

        </Box>
      </Container>
    </Box>
  </>
)};

export default PickupNoLocationRouteMapPage;
