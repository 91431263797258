/* eslint-disable */
import { useState,useEffect } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import APagination from '../../pagination';
import Loading from '../../loading';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';


const CustomerListed = ({ datas,totalRecord,onChangePage,onChangeRowsPerPage,pageIndex,pageSize,isFetching,DataTable,columns,
  ...rest }) => {



  return (
    <Card {...rest}>
      {isFetching && <Loading/>}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Customer Code
                </TableCell>
                <TableCell>
                  Customer Name
                </TableCell>
                <TableCell>
                  Lat
                </TableCell>
                <TableCell>
                  Long
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {datas && datas.map((data) => (
                  <TableRow
                  hover
                  key={data.id}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >

                        {data.custCode}

                    </Box>
                  </TableCell>
                  <TableCell>
                  {data.custName}
                  </TableCell>
                  <TableCell>
                  {data.lat}
                  </TableCell>
                  <TableCell>
                  {data.long}
                  </TableCell>

                  <TableCell>
                    <Button
                    href={`/app/customers/${data.gid}`}
                    color="primary" startIcon={<CreateIcon />} >EDIT</Button>
                    {/* <Button color="primary" startIcon={<DeleteIcon />} >DELETE</Button> */}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <APagination total={totalRecord}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage} />
    </Card>
  );
};

export default CustomerListed;
