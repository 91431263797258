export const initialCriteria = {
    docNo: '',
    searchText: '',
    docKey: 0,
    refDocNo: '',
    refDocKey: 0,
    refDocKey1: 0,
    refDocKey2: 0,
    refDocKey3: 0,
    refDocKey4: 0,
    refDocKey5: 0,
    id: 0,
    refDouble1: 0,
    refDouble2: 0,
    status: [
      0
    ],
    startDate: '2021-08-14T00:57:01.567Z',
    endDate: '2021-08-14T00:57:01.567Z',
    docDate: '2021-08-14T00:57:01.567Z'
  }
