import { Button } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from "react-router-dom";


const BackButton = () => {
    const navigate = useNavigate();

    return (
        <Button variant="outlined" startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)}
        >
           CANCEL
        </Button>
    );
}

export default BackButton;