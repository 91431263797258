import { Button } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useNavigate } from "react-router-dom";


const NavButton = ({navUrl,navTitle,onClick}) => {
    const navigate = useNavigate();

    return (
        <Button variant="outlined" startIcon={<PersonAddIcon />}
        onClick={() => navigate(navUrl)
        }
        >
           {navTitle}
        </Button>
    );
}

export default NavButton;