import { TextField } from '@material-ui/core';
import * as React from 'react';

// type Props = {

// }

const TextInput = (props) => {
    return (
        // <TextField variant="outlined" fullWidth={true} size="small" {...props} />
        <TextField  fullWidth={true} size="small" variant="outlined" {...props}/>

    );
}

export default TextInput;